import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { jwtDecode } from 'jwt-decode';

const DataManager = () => {
  const [heroData, setHeroData] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [newTier, setNewTier] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const baseAttackTypes = ['Melee', 'Range'];

  const fetchCommonData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        alert('Session expired. Please log in again.');
        navigate('/login');
        return;
      }
  
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        alert('Session expired. Please log in again.');
        localStorage.removeItem('token');
        navigate('/login');
        return;
      }

      const response = await axios.get('/api/common-data', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedHeroes = sortHeroData(response.data.heroes);
      setHeroData(sortedHeroes);
      setTiers(response.data.commonTiers.sort());
    } catch (error) {
      console.error('Error fetching common data:', error);
    } finally {
      setLoading(false);
    }
  };

  const sortHeroData = (heroes) => {
    return heroes.map((hero) => ({
      ...hero,
      keyId: [...hero.keyId].sort(),
      sideEffectAttack: {
        effects: hero.sideEffectAttack.effects.sort((a, b) =>
          a.effectType.localeCompare(b.effectType)
        ),
      },
      skillAttack: {
        skills: hero.skillAttack.skills.sort((a, b) =>
          a.skillType.localeCompare(b.skillType)
        ),
      },
    })).sort((a, b) => a.heroType.localeCompare(b.heroType));
  };

  const addHeroKeyId = (heroIndex, newKeyId) => {
    if (!newKeyId) {
      alert('Key ID cannot be empty!');
      return;
    }
    const updatedData = [...heroData];
    updatedData[heroIndex].keyId = [...new Set([...updatedData[heroIndex].keyId, newKeyId])].sort();
    setHeroData(updatedData);
  };

  const removeHeroKeyId = (heroIndex, keyIdToRemove) => {
    const updatedData = [...heroData];
    updatedData[heroIndex].keyId = updatedData[heroIndex].keyId.filter((key) => key !== keyIdToRemove);
    setHeroData(updatedData);
  };

  const updateBaseAttackType = (heroIndex, newType) => {
    const updatedData = [...heroData];
    updatedData[heroIndex].baseAttack.attackType = newType;
    setHeroData(updatedData);
  };

  const addBaseAttackKeyId = (heroIndex, newKeyId) => {
    if (!newKeyId) {
      alert('Key ID cannot be empty!');
      return;
    }
    const updatedData = [...heroData];
    updatedData[heroIndex].baseAttack.keyId = [...new Set([...updatedData[heroIndex].baseAttack.keyId, newKeyId])].sort();
    setHeroData(updatedData);
  };

  const removeBaseAttackKeyId = (heroIndex, keyIdToRemove) => {
    const updatedData = [...heroData];
    updatedData[heroIndex].baseAttack.keyId = updatedData[heroIndex].baseAttack.keyId.filter((key) => key !== keyIdToRemove);
    setHeroData(updatedData);
  };

  const addSideEffect = (heroIndex, keyId, effectType) => {
    if (!keyId || !effectType) {
      alert('Both Key ID and Effect Type are required!');
      return;
    }

    const updatedData = [...heroData];
    const sideEffect = updatedData[heroIndex].sideEffectAttack.effects.find(
      (effect) => effect.effectType === effectType
    );

    if (sideEffect) {
      sideEffect.keyId = [...new Set([...sideEffect.keyId, keyId])].sort();
    } else {
      updatedData[heroIndex].sideEffectAttack.effects.push({
        effectType,
        keyId: [keyId],
      });
    }

    updatedData[heroIndex].sideEffectAttack.effects.sort((a, b) =>
      a.effectType.localeCompare(b.effectType)
    );

    setHeroData(updatedData);
  };

  const removeSideEffectKey = (heroIndex, effectType, keyId) => {
    const updatedData = [...heroData];
    const sideEffect = updatedData[heroIndex].sideEffectAttack.effects.find(
      (effect) => effect.effectType === effectType
    );

    if (sideEffect) {
      sideEffect.keyId = sideEffect.keyId.filter((key) => key !== keyId);
      if (sideEffect.keyId.length === 0) {
        updatedData[heroIndex].sideEffectAttack.effects = updatedData[heroIndex].sideEffectAttack.effects.filter(
          (effect) => effect.effectType !== effectType
        );
      }
    }

    setHeroData(updatedData);
  };

  const addSkillEffect = (heroIndex, keyId, skillType) => {
    if (!keyId || !skillType) {
      alert('Both Key ID and Skill Type are required!');
      return;
    }

    const updatedData = [...heroData];
    const skill = updatedData[heroIndex].skillAttack.skills.find(
      (skill) => skill.skillType === skillType
    );

    if (skill) {
      skill.keyId = [...new Set([...skill.keyId, keyId])].sort();
    } else {
      updatedData[heroIndex].skillAttack.skills.push({
        skillType,
        keyId: [keyId],
      });
    }

    updatedData[heroIndex].skillAttack.skills.sort((a, b) =>
      a.skillType.localeCompare(b.skillType)
    );

    setHeroData(updatedData);
  };

  const removeSkillEffectKey = (heroIndex, skillType, keyId) => {
    const updatedData = [...heroData];
    const skill = updatedData[heroIndex].skillAttack.skills.find(
      (skill) => skill.skillType === skillType
    );

    if (skill) {
      skill.keyId = skill.keyId.filter((key) => key !== keyId);
      if (skill.keyId.length === 0) {
        updatedData[heroIndex].skillAttack.skills = updatedData[heroIndex].skillAttack.skills.filter(
          (skill) => skill.skillType !== skillType
        );
      }
    }

    setHeroData(updatedData);
  };

  const addTier = () => {
    if (newTier && !tiers.includes(newTier)) {
      setTiers([...tiers, newTier].sort());
      setNewTier('');
    }
  };

  const removeTier = (tier) => {
    setTiers(tiers.filter((t) => t !== tier));
  };

  const saveData = async () => {
    try {
      const token = localStorage.getItem('token');
      const sortedHeroes = sortHeroData(heroData);
      await axios.post(
        '/api/save-common-data',
        { commonTiers: tiers, heroes: sortedHeroes },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Data saved successfully!');
      fetchCommonData();
    } catch (error) {
      console.error('Error saving data:', error);
      alert('Failed to save data.');
    }
  };

  useEffect(() => {
    fetchCommonData();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="data-manager">
      <h2 className="title">Data Manager</h2>

      {/* Manage Tiers */}
      <div className="section">
        <h3 className="card-title">Manage Common Tiers</h3>
        <div className="form-group">
          <input
            type="text"
            className="input"
            placeholder="Add New Tier"
            value={newTier}
            onChange={(e) => setNewTier(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && addTier()}
          />
          <button className="button" onClick={addTier}>
            Add Tier
          </button>
        </div>
        <ul className="list">
          {tiers.map((tier, index) => (
            <li key={index} className="list-item">
              {tier}
              <button className="button button-remove" onClick={() => removeTier(tier)}>
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Manage Heroes */}
      <div className="section">
        <h3 className="card-title">Hero Data</h3>
        {heroData.map((hero, heroIndex) => (
          <div key={heroIndex} className="section">
            <h4 className="card-title">Hero Type: {hero.heroType}</h4>
            {/* Base Attack */}
            <div className="item-section">
              <strong>Base Attack:</strong>
              <div className="form-group">
                <label htmlFor={`baseAttackType-${heroIndex}`}>Attack Type:</label>
                <select
                  id={`baseAttackType-${heroIndex}`}
                  className="input"
                  value={hero.baseAttack.attackType}
                  onChange={(e) => updateBaseAttackType(heroIndex, e.target.value)}
                >
                  {baseAttackTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <ul className="nested-list">
                {hero.baseAttack.keyId.map((key) => (
                  <li key={key} className="nested-list-item">
                    {key}
                    <button
                      className="button button-remove"
                      onClick={() => removeBaseAttackKeyId(heroIndex, key)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
              <div className="form-group">
                <input
                  type="text"
                  className="input"
                  placeholder="Add New Base Attack Key ID"
                  id={`baseAttackKey-${heroIndex}`}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const keyInput = document.getElementById(`baseAttackKey-${heroIndex}`);
                      addBaseAttackKeyId(heroIndex, keyInput.value);
                      keyInput.value = '';
                    }
                  }}
                />
                <button
                  className="button"
                  onClick={() => {
                    const keyInput = document.getElementById(`baseAttackKey-${heroIndex}`);
                    addBaseAttackKeyId(heroIndex, keyInput.value);
                    keyInput.value = '';
                  }}
                >
                  Add Key ID
                </button>
              </div>

              {/* Key IDs */}
              <div className="item-section">
                <strong>Key IDs:</strong>
                <ul className="nested-list">
                  {hero.keyId.map((key) => (
                    <li key={key} className="nested-list-item">
                      {key}
                      <button
                        className="button button-remove"
                        onClick={() => removeHeroKeyId(heroIndex, key)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="form-group">
                  <input
                    type="text"
                    className="input"
                    placeholder="Add New Key ID"
                    id={`heroKeyId-${heroIndex}`}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const keyInput = document.getElementById(`heroKeyId-${heroIndex}`);
                        addHeroKeyId(heroIndex, keyInput.value);
                        keyInput.value = '';
                      }
                    }}
                  />
                  <button
                    className="button"
                    onClick={() => {
                      const keyInput = document.getElementById(`heroKeyId-${heroIndex}`);
                      addHeroKeyId(heroIndex, keyInput.value);
                      keyInput.value = '';
                    }}
                  >
                    Add Key ID
                  </button>
                </div>
              </div>
            </div>

            {/* Side Effect Attack */}
            <div className="item-section">
              <strong>Side Effect Attacks:</strong>
              <ul className="nested-list">
                {hero.sideEffectAttack.effects.map((effect) => (
                  <li key={effect.effectType} className="nested-list-item">
                    <strong>{effect.effectType}:</strong>
                    <ul className="nested-list">
                      {effect.keyId.map((key) => (
                        <li key={key} className="nested-list-item">
                          {key}
                          <button
                            className="button button-remove"
                            onClick={() => removeSideEffectKey(heroIndex, effect.effectType, key)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
              <div className="form-group">
                <input
                  type="text"
                  className="input"
                  placeholder="Effect Type"
                  id={`sideEffectType-${heroIndex}`}
                />
                <input
                  type="text"
                  className="input"
                  placeholder="Key ID"
                  id={`sideEffectKey-${heroIndex}`}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const keyInput = document.getElementById(`sideEffectKey-${heroIndex}`);
                      const typeInput = document.getElementById(`sideEffectType-${heroIndex}`);
                      addSideEffect(heroIndex, keyInput.value, typeInput.value);
                      keyInput.value = '';
                    }
                  }}
                />
                <button
                  className="button"
                  onClick={() => {
                    const keyInput = document.getElementById(`sideEffectKey-${heroIndex}`);
                    const typeInput = document.getElementById(`sideEffectType-${heroIndex}`);
                    addSideEffect(heroIndex, keyInput.value, typeInput.value);
                    keyInput.value = '';
                  }}
                >
                  Add Side Effect
                </button>
              </div>
            </div>

            {/* Skill Attack */}
            <div className="item-section">
              <strong>Skill Attacks:</strong>
              <ul className="nested-list">
                {hero.skillAttack.skills.map((skill) => (
                  <li key={skill.skillType} className="nested-list-item">
                    <strong>{skill.skillType}:</strong>
                    <ul className="nested-list">
                      {skill.keyId.map((key) => (
                        <li key={key} className="nested-list-item">
                          {key}
                          <button
                            className="button button-remove"
                            onClick={() => removeSkillEffectKey(heroIndex, skill.skillType, key)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
              <div className="form-group">
                <input
                  type="text"
                  className="input"
                  placeholder="Skill Type"
                  id={`skillType-${heroIndex}`}
                />
                <input
                  type="text"
                  className="input"
                  placeholder="Key ID"
                  id={`skillKey-${heroIndex}`}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const keyInput = document.getElementById(`skillKey-${heroIndex}`);
                      const typeInput = document.getElementById(`skillType-${heroIndex}`);
                      addSkillEffect(heroIndex, keyInput.value, typeInput.value);
                      keyInput.value = '';
                    }
                  }}
                />
                <button
                  className="button"
                  onClick={() => {
                    const keyInput = document.getElementById(`skillKey-${heroIndex}`);
                    const typeInput = document.getElementById(`skillType-${heroIndex}`);
                    addSkillEffect(heroIndex, keyInput.value, typeInput.value);
                    keyInput.value = '';
                  }}
                >
                  Add Skill
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button className="button button-save" onClick={saveData}>
        Save Data
      </button>
    </div>
  );
};

export default DataManager;

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// Sortable row component using dnd-kit
const SortableRow = ({ row, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: row.original.unique_id, // Each row should have a unique id
    });
  
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
  
    return (
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {children}
      </tr>
    );
  };

export default SortableRow;
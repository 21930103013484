import React from 'react';

// Editable Dropdown Component for select inputs
const EditableDropdown = ({
  value, // Controlled value passed from the parent
  row: { index },
  column: { id },
  updateMyData,
  options = [],
  disabled = false,
}) => {
  const onChange = (e) => {
    const selectedValue = e.target.value;
    updateMyData(index, id, selectedValue || ''); // Update parent's state directly
  };

  return (
    <select
      value={value || ''} // Controlled by parent
      onChange={onChange}
      className="form-control"
      disabled={disabled} // Disable dropdown when necessary
    >
      <option value="">Select...</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default EditableDropdown;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          // Token is expired, clear it
          localStorage.removeItem('token');
        } else {
          // Token is valid, navigate to main page
          navigate('/main');
        }
      } catch (err) {
        console.error('Invalid token:', err);
        localStorage.removeItem('token'); // Clear invalid token
      }
    }
  }, [navigate]);

  const handleLogin = () => {
    if (!email || !password) {
      setErrorMessage('Email and password are required!');
      return;
    }

    axios
      .post('/api/login', { email, password })
      .then((response) => {
        localStorage.setItem('token', response.data.token); // Save the token
        navigate('/main'); // Navigate to main page after successful login
      })
      .catch(() => {
        setErrorMessage('Invalid email or password');
      });
  };

  // Handle key press event
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin(); // Trigger login when Enter key is pressed
    }
  };

  return (
    <div id="loginform">
      <h2 id="headerTitle">JDL Admin Page</h2>
      <div className="row">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyPress} // Add key press event for the email input
        />
      </div>
      <div className="row">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyPress} // Add key press event for the password input
        />
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="row" id="button">
        <button onClick={handleLogin}>Log In</button>
      </div>
    </div>
  );
}

export default Login;
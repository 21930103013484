import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTable } from 'react-table';
import axios from 'axios';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { KeyboardSensor, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import EditableCell from './EditableCell';
import EditableDropdown from './EditableDropdown';
import SortableRow from './SortableRow';
import * as XLSX from 'sheetjs-style';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function EnemyData() {
  const [data, setData] = useState([]);
  const [keyIdOptions, setKeyIdOptions] = useState([]);
  const [enemyTypeOptions, setEnemyTypeOptions] = useState([]);
  const [isDataModified, setIsDataModified] = useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      alert('Session expired. Please log in again.');
      navigate('/login');
      return;
    }

    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      alert('Session expired. Please log in again.');
      localStorage.removeItem('token');
      navigate('/login');
      return;
    }

    axios
      .get('/api/enemy-data', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const enemiesWithId = response.data.enemies.map((enemy, index) => ({
          ...enemy,
          unique_id: `${index}-${Date.now()}`, // Generate unique ID for each row
        }));

        setData(enemiesWithId);

        const uniqueKeyIds = [...new Set(enemiesWithId.map((enemy) => enemy.keyId))];
        const uniqueEnemyTypes = [...new Set(enemiesWithId.map((enemy) => enemy.enemyType))];

        setKeyIdOptions(uniqueKeyIds);
        setEnemyTypeOptions(uniqueEnemyTypes);
      })
      .catch((error) => console.error('Error fetching enemy data:', error));
  }, []);

  const saveData = () => {
    console.log("save data");
    const token = localStorage.getItem('token');
    axios
      .post('/api/save-enemy-data', { enemies: data }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        alert('Enemy data saved successfully!');
        setIsDataModified(false);
      })
      .catch((error) => console.error('Error saving enemy data:', error));
  };

  const moveRow = useCallback((activeIndex, overIndex) => {
    if (activeIndex !== overIndex) {
      setData((old) => arrayMove(old, activeIndex, overIndex));
      setIsDataModified(true);
    }
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
    setIsDataModified(true);
  };

  const addRow = () => {
    const newRow = {
      keyId: '',
      enemyType: '',
      level: 0,
      hp: 0,
      moveSpeed: 0,
      enemyCount: 0,
      armor: 0,
      gold: 0,
      unique_id: `${data.length}-${Date.now()}`, // Ensure a unique ID
    };
    setData((old) => [...old, newRow]);
    setIsDataModified(true);
    scrollToBottom();
  };

  const removeRow = (index) => {
    setData((old) => old.filter((_, i) => i !== index));
    setIsDataModified(true);
  };

  const scrollToBottom = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const flattenData = (data) => {
    return data.map((row) => ({
      keyId: row.keyId || '',
      enemyType: row.enemyType || '',
      level: row.level || 0,
      hp: row.hp || 0,
      moveSpeed: row.moveSpeed || 0,
      enemyCount: row.enemyCount || 0,
      armor: row.armor || 0,
      gold: row.gold || 0,
    }));
  };

  const exportToExcel = () => {
    if (!data || data.length === 0) {
      alert('No data available to export');
      return;
    }

    const flattenedData = flattenData(data);
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddress]) continue;
      worksheet[cellAddress].s = {
        font: { bold: true, color: { rgb: '000000' } },
        fill: { patternType: 'solid', fgColor: { rgb: 'DDEBF7' } },
        alignment: { horizontal: 'center', vertical: 'center' },
      };
    }

    const colWidths = Object.keys(flattenedData[0]).map((key) => {
      const maxContentLength = Math.max(
        key.length,
        ...flattenedData.map((row) => String(row[key] || '').length)
      );
      return { wch: maxContentLength + 2 };
    });
    worksheet['!cols'] = colWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Enemy Data');

    XLSX.writeFile(workbook, 'EnemyData.xlsx');
  };

  const importFromExcel = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const importedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const formattedData = importedData.slice(1).map((row, index) => ({
        keyId: row[0] || '',
        enemyType: row[1] || '',
        level: row[2] || 0,
        hp: row[3] || 0,
        moveSpeed: row[4] || 0,
        enemyCount: row[5] || 0,
        armor: row[6] || 0,
        gold: row[7] || 0,
        unique_id: `${index}-${Date.now()}`,
      }));

      setData(formattedData);
      alert('Data imported successfully!');
      event.target.value = '';
      setIsDataModified(true);
    };

    reader.readAsBinaryString(file);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 200, tolerance: 6 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const columns = React.useMemo(
    () => [
      { Header: '', id: 'drag', Cell: () => <span className="drag-handle">&#9776;</span> },
      { Header: 'Key ID', accessor: 'keyId', Cell: (props) => <EditableDropdown {...props} options={keyIdOptions} /> },
      { Header: 'Enemy Type', accessor: 'enemyType', Cell: (props) => <EditableDropdown {...props} options={enemyTypeOptions} /> },
      { Header: 'Level', accessor: 'level', Cell: EditableCell, type: 'number' },
      { Header: 'HP', accessor: 'hp', Cell: EditableCell, type: 'number' },
      { Header: 'Move Speed', accessor: 'moveSpeed', Cell: EditableCell, type: 'number' },
      { Header: 'Enemy Count', accessor: 'enemyCount', Cell: EditableCell, type: 'number' },
      { Header: 'Armor', accessor: 'armor', Cell: EditableCell, type: 'number' },
      { Header: 'Gold', accessor: 'gold', Cell: EditableCell, type: 'number' },
      { Header: 'Actions', id: 'actions', Cell: ({ row }) => <button className="btn btn-danger" onClick={() => removeRow(row.index)}>Remove</button> },
    ],
    [keyIdOptions, enemyTypeOptions]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    updateMyData,
  });

  return (
    <div className="container">
      <h1 className="text-center">Enemy Data</h1>
      <button className="save-button" onClick={saveData} disabled={!isDataModified}>Save Enemy Data</button>
      <button className="save-button" onClick={exportToExcel} style={{ marginLeft: '20px' }}>Export to Excel</button>
      <button className="save-button" onClick={addRow} style={{ marginLeft: '20px' }}>Add Row</button>
      <input type="file" accept=".xlsx, .xls" onChange={importFromExcel} style={{ display: 'inline-block', marginLeft: '20px' }} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={({ active, over }) => {
          if (active && over) {
            const activeIndex = rows.findIndex((row) => row.original.unique_id === active.id);
            const overIndex = rows.findIndex((row) => row.original.unique_id === over.id);
            moveRow(activeIndex, overIndex);
          }
        }}
        modifiers={[restrictToVerticalAxis]}
      >
        <div className="table-container">
          <table {...getTableProps()} className="custom-table" ref={tableRef}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <SortableContext items={rows.map((row) => row.original.unique_id)}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <SortableRow key={row.original.unique_id} row={row} moveRow={moveRow}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </SortableRow>
                  );
                })}
              </SortableContext>
            </tbody>
          </table>
        </div>
      </DndContext>
    </div>
  );
}

export default EnemyData;

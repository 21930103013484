import React, { useState, useEffect, useRef } from 'react';

const EditableCell = ({
  value: initialValue, // Initial cell value
  row: { index }, // Row index
  column: { id }, // Column ID
  updateMyData, // Function to update data
  type = 'text', // Input type: 'text' or 'number'
  disabled = false, // If input should be disabled
  step = '0.01', // Step size for numeric inputs
}) => {
  const [localValue, setLocalValue] = useState(initialValue); // Local state for input value
  const inputRef = useRef(null);

  const numericColumns = ['level', 'hp', 'moveSpeed', 'enemyCount', 'armor', 'gold']; // Example numeric column IDs
  const isNumeric = type === 'number' || numericColumns.includes(id); // Determine if the column should accept numeric input

  // Update local value when initialValue changes (e.g., from table refresh)
  useEffect(() => {
    setLocalValue(initialValue);
  }, [initialValue]);

  // Update disabled state when the prop changes
  useEffect(() => {
    if (disabled) {
      inputRef.current?.blur(); // Remove focus if the field becomes disabled
    }
  }, [disabled]);

  // Handle input change
  const onChange = (e) => {
    setLocalValue(e.target.value);
  };

  // Handle input blur (focus loss)
  const onBlur = () => {
    let newValue = localValue;

    if (isNumeric) {
      newValue = parseFloat(localValue);
      if (!isNaN(newValue)) {
        newValue = parseFloat(newValue.toFixed(2)); // Round to 2 decimal points
      } else {
        newValue = 0; // Fallback to 0 if value is invalid
      }
    }

    // Only update global state when input loses focus
    updateMyData(index, id, newValue || '');
  };

  return (
    <input
      ref={inputRef} // Reference for advanced usage like auto-focus
      value={localValue || ''} // Controlled input value
      onChange={onChange} // Update local state on change
      onBlur={onBlur} // Update global state on blur
      type={isNumeric ? 'number' : 'text'} // Set input type based on column type
      step={isNumeric ? step : undefined} // Numeric step for precise inputs
      className={`form-control ${disabled ? 'disabled' : ''}`} // Add disabled class for styling
      disabled={disabled} // Disable input if necessary
      key={`${index}-${id}`} // Stable key to prevent losing focus during re-renders
    />
  );
};

export default EditableCell;
